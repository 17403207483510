/* Uygulama Kartları */
.apps-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  justify-content: center;
}



.app-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}


.app-card h3 {
  font-size: 1.6rem;
  margin: 15px 20px;
  color: #333333;
}

.app-card {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative; /* Z-index için gerekli */
  transition: transform 0.3s, box-shadow 0.3s;
}

.app-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 5px;
  height: 100%;
  background-color: #f4813f; 
  z-index: 1; 
}

.app-card .carousel {
  display: flex;
  overflow-x: auto;
  gap: 10px;
  margin: 10px;
  background-color: #f9f9f9; /* Arka plan rengi */
  position: relative; /* Çizginin üzerine gelmemesi için */
  z-index: 0; /* Çizginin altında kalması için */
}


.app-card .carousel-item {
  flex: 0 0 auto;
  width: 120px; /* Görsel genişliği */
  height: 120px; /* Görsel yüksekliği */
  border-radius: 8px;
  overflow: hidden;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px; /* Görselin etrafında padding */

}

.app-card .carousel-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.carousel {
  display: flex;
  overflow-x: auto;
  gap: 10px;
}

.carousel img {
  height: 200px;
  border-radius: 10px;
  flex-shrink: 0;
}

.app-card p {
  font-size: 1rem;
  color: #666666;
  margin: 0 20px 15px;
}

.center-heading h2 {
  margin-top: 20px;
  text-align: center;
  font-size: 42px;
  font-weight: 700;
  color: #1e1e1e;
  margin-bottom: 25px;
}

.center-heading h2 em {
  font-style: normal;
  color: #f4813f;
}
/* Oynatma butonu */
.play-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #f4813f;
  color: #fff;
  border: none;
  border-radius: 50%; 
  width: 40px; 
  height: 40px;
  font-size: 20px; 
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 2; 
}

/* Video Modal */
.video-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Modal'ı üstte göstermek için */
}

.video-content {
  position: relative;
  width: 80%;
  max-width: 800px;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Modal için gölge ekleyin */
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #f4813f;
  color: #fff;
  border: none;
  border-radius: 50%; /* Tam yuvarlak buton */
  width: 40px; /* Butonun genişliği */
  height: 40px; /* Butonun yüksekliği */
  font-size: 24px; /* İkonun boyutu */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3); /* Küçük bir gölge ekleyin */
}

iframe {
  width: 100%;
  height: 450px;
  border: none;
}
