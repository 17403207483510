
.snackbar {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #333;
    color: white;
    padding: 16px;
    z-index: 1000;
    animation: slideIn 0.5s; /* Animasyon ekleyebilirsiniz */
  }
  
  .snackbar a {
    color: #ffa500; /* Link için turuncu renk */
    font-weight: bold;
    margin-left: 10px;
  }
  
  .snackbar-dismiss {
    margin-left: 10px;
    background-color: #f44336;
    color: white;
    border: none;
    padding: 5px;
    cursor: pointer;
  }
  
  /* Animasyon */
  @keyframes slideIn {
    from { transform: translateY(100%); }
    to { transform: translateY(0); }
  }
  