/* Lightbox Modal */
.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow: hidden;
}

.lightbox img {
  max-width: 90%;
  max-height: 80%;
  border-radius: 8px;
}

.close-lightbox,
.prev,
.next {
  position: absolute;
  background-color: #f4813f;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.close-lightbox {
  top: 20px;
  right: 20px;
}

.prev {
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.next {
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}
